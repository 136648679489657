* {
  margin: 0;
  padding: 0;
}

html {
  background: #1e1a20;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
.section-vice-versa {
  display: flex;
  height: 100vh;
  position: relative;
  font-family: "Cabin", sans-serif;
  color: #ffeded;
  /* text-transform: uppercase; */
  font-size: 7vmin;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  row-gap: 1ch;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}

.section-vice-versa-p {
  max-width: 40vw;
}

.section {
  display: flex;
  align-items: center;
  height: 100vh;
  position: relative;
  font-family: "Cabin", sans-serif;
  color: #ffeded;
  /* text-transform: uppercase; */
  font-size: 7vmin;
  padding-left: 10%;
  padding-right: 10%;
}

section:nth-child(odd) {
  justify-content: flex-end;
}

@media screen and (max-width: 700px) {
  .section {
    max-width: 100vw;
  }
  .section-vice-versa {
    max-width: 100vw;
  }
  .section-vice-versa-p {
    max-width: 100vw;
  }
}

@media screen and (min-width: 701px) {
  .section {
    max-width: 40vw;
  }
  .section-vice-versa {
    /* max-width: 40vw; */
  }
  .section-vice-versa-p {
    max-width: 40vw;
  }
}
